/*eslint-disable */
import {
  mapGetters
} from 'vuex'; 
export default {
  name: 'public-pres-info',
  components: {},
  props: [],
  data () {
    return {
      publicPrescritionData:null
    }
  },
  computed: {
    ...mapGetters([
      'publicPrescritionGetters'
    ]),
    getPublicPrescritionDataMethod() {
      return this.publicPrescritionData = this.$store.getters.publicPrescritionGetters.data
    },
  },
  mounted () {
    this.getPublicPrescritionDataMethod
  },
  methods: {

  },
  watch:{
    getPublicPrescritionDataMethod() {
      return this.$store.getters.publicPrescritionGetters
    },
  },
  beforeCreate: function() {
    document.body.classList.add('publicPrescritionBody')
  },
  beforeRouteLeave: function(to, from, next) {
    document.body.classList.remove('publicPrescritionBody')
    next();
  },
}


